<template>
  <div class="box">
    <el-button
      type="primary"
      size="medium"
      @click="downloadFile"
    >
      数据导出
    </el-button>
    <div class="box-toolbar">
      <div class="box-item">
        <el-input
          placeholder="订单号/手机号码/姓名"
          v-model="content"
          clearable
          @keyup.enter.native="searchGo"
        >
        </el-input>
      </div>
      <div class="box-item">
        <el-select
          v-model="shopId"
          placeholder="选择门店"
          @change="changeShopsStatus"
        >
          <el-option
            v-for="(item,index) in shops"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div
        class="box-item"
        style="width: 120px;"
      >
        <el-select
          v-model="status"
          placeholder="选择状态"
          @change="changeTypesStatus"
        >
          <el-option
            v-for="(item,index) in Status"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div
        class="box-item"
        style="width: 110px;"
      >
        <el-select
          v-model="type"
          placeholder="选择类型"
          @change="changeTypesStatus"
        >
          <el-option
            v-for="(item,index) in Types"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-item">
        <el-button
          icon="el-icon-search"
          circle
          @click="searchGo()"
        ></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      :stripe="true"
      :data="lists"
      height="75vh"
    >

      <el-table-column
        width="60"
        prop="id"
        label="订单id"
      >
      </el-table-column>
      <el-table-column
        width="220"
        prop="order_no"
        label="订单号"
      >
      </el-table-column>

      <el-table-column
        prop="store_id"
        label="门店"
      >
        <template slot-scope="scope">
          {{scope.row.store.bsName}}
        </template>
      </el-table-column>

      <el-table-column
        width="100"
        prop="status"
        label="订单状态"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 1"
            type="info"
            size='mini'
            plain
          >未支付</el-button>
          <el-button
            v-if="scope.row.status == 2"
            type="primary"
            size='mini'
            plain
          >已支付</el-button>
          <el-button
            v-if="scope.row.status == 3"
            type="success"
            size='mini'
            plain
          >已完成</el-button>
          <el-button
            v-if="scope.row.status == 5"
            type="warning"
            size='mini'
            plain
          >已退款</el-button>
          <el-button
            v-if="scope.row.status == 6"
            type="warning"
            size='mini'
            plain
          >部分退款</el-button>
          <el-button
            v-if="scope.row.status == 8"
            type="info"
            size='mini'
            plain
          >已关闭</el-button>
          <el-button
            v-if="scope.row.status == 99"
            type="info"
            size='mini'
            plain
          >交易关闭</el-button>
        </template>
      </el-table-column>

      <el-table-column
        prop="type"
        label="订单类型"
      >
        <template slot-scope="scope">
          {{scope.row.type == 1?'堂食':''}}
          {{scope.row.type == 2?'带走':''}}
          {{scope.row.type == 3?'外卖':''}}
          {{scope.row.type == 4?'扫码购':''}}
        </template>
      </el-table-column>

      <el-table-column
        prop="actual_payment"
        label="支付金额"
      >
      </el-table-column>

      <el-table-column
        prop="actual_payment"
        label="找零"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.price"
            :style="scope.row.price.backPrice > 0 ?'color:red':''"
          >
            {{scope.row.price.backPrice}}
          </span>
          <span
            style="color:red;"
            v-else-if="scope.row.payment - scope.row.actual_payment >0  && scope.row.actual_payment >0"
          >
            {{(scope.row.payment - scope.row.actual_payment).toFixed(2)}}
          </span>
          <span v-else>0.00</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="message"
        label="备注"
      >
      </el-table-column>
      <el-table-column
        width="180"
        prop="payment_at"
        label="付款时间"
      >
      </el-table-column>

      <el-table-column
        prop="user_name"
        label="付款人"
      >
      </el-table-column>
      <el-table-column
        width="120"
        prop="user_number"
        label="联系方式"
      >
      </el-table-column>

      <el-table-column
        fixed="right"
        width="300"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            plain
            size="mini"
            type="primary"
            v-if="$can('admin/order/view')"
            @click.native="openViewOrder(scope.row)"
          >
            查看
          </el-button>
          <el-button
            plain
            size="mini"
            type="danger"
            v-if="$can('admin/order/refund')"
            @click.native="openRefundOrder(scope.row)"
          >退款</el-button>

        </template>
      </el-table-column>
    </el-table>
    <page
      :pageSize="limit"
      :count="count"
      @change="pageChange"
      @changeSize="changeSize"
    >
    </page>
    <view-order ref="view" />
    <refund
      ref="refund"
      @refund="refundOrder"
    />
  </div>
</template>

<script>
import Page from '@admin/components/Pagination'
import OrderService from '@admin/services/OrderService'
import FileService from '@admin/services/FileService'
import pagination from '@admin/mixins/PaginationMixin'
import ShopService from '@admin/services/ShopService'
import flatry from '@admin/utils/flatry'
import refund from './components/refund'
import viewOrder from './components/view'

export default {
  components: { Page, refund, viewOrder },
  data () {
    const Types = [
      {
        id: '',
        name: '全部类型'
      },
      {
        id: 1,
        name: '堂食'
      },
      {
        id: 2,
        name: '带走'
      },
      {
        id: 4,
        name: '扫码购'
      }
    ]
    const Status = [
      {
        id: '',
        name: '全部状态'
      },
      {
        id: 1,
        name: '未支付'
      },
      {
        id: 2,
        name: '已支付'
      },
      {
        id: 3,
        name: '已完成'
      },
      {
        id: 5,
        name: '已退款'
      },
      {
        id: 6,
        name: '部分退款'
      },
      {
        id: 99,
        name: '交易已关闭'
      }
    ]
    return {
      loading: true,
      function: function (param) {
        return OrderService.all(param)
      },
      content: '',
      // 以下字段搜索使用
      type: '',
      status: '',
      Types: Types,
      Status: Status,
      shopId: '',
      shops: []
    }
  },
  mixins: [pagination],
  methods: {
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '当页总价'
          return
        }
        const indexs = [1, 2, 3, 5, 6, 7, 8, 9]
        if (index !== 0 && indexs.indexOf(index) >= 0) {
          sums[index] = ''
          return
        }
        const values = data.map((item) =>
          Number(item[column.property])
        )
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = sums[index].toFixed(2)
          sums[index] += ' 元'
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    },
    async searchGo (isSave = false) {
      if (isSave === false) {
        this.page = 1
      }
      let param = {
        content: this.content,
        store_id: this.shopId,
        status: this.status,
        type: this.type
      }
      this.where = param
      await this.getList(param)
    },
    async getShops () {
      const { data } = await flatry(ShopService.shops())
      let shop = [
        {
          id: '',
          name: '全部店铺'
        }
      ]
      if (data) {
        this.shops = shop.concat(data.data)
      } else {
        this.shops = shop
      }
    },
    changeShopsStatus () {
      this.searchGo()
    },
    // 切换type 状态
    changeTypesStatus () {
      this.searchGo()
    },
    // 退款请求
    async refundOrder (item) {
      const { data } = await flatry(OrderService.refund(item))
      if (data) {
        this.$message.success(data.msg)

        this.$refs.refund.close()

        this.searchGo(true)
      }
    },
    openRefundOrder (item) {
      this.$refs.refund.openModel(item)
    },
    openViewOrder (item) {
      this.$refs.view.openModel(item)
    },
    async downloadFile () {
      let param = {
        type: 'order'
      }
      const { data } = await flatry(
        FileService.downloadFile(null, param)
      )
      if (data) {
        console.log(data)
      }
    }
  },
  async created () {
    await this.getShops()
  }
}
</script>

<style scoped>
</style>
